<template>
<div>
  <columns>
    <column class="is-9">
      <columns>
        <column class="is-5">
          <h4 class="subtitle is-5 has-text-weight-light mb-3">Plan <span class="tag is-warning" v-if="subscription.on_trial">ON
              TRIAL</span></h4>
          <h2 class="title is-2">
              {{ subscription.price.plan.title }}
              <small class="has-text-weight-light" v-if="!subscription.on_trial">({{ subscription.annual ? 'Annual' : 'Monthly' }})</small>
          </h2>
          <p v-if="subscription.on_trial">Trial expires on {{ subscription.trial_expires_at | date }}</p>
          <router-link 
            class="button is-small is-rounded is-warning"
            :to="{
              name: 'plans'
            }">Change Your Plan</router-link>
        </column>
        <column>
          <div>
            <p class="title is-5">Usage</p>
            <div class="is-flex is-justify-content-space-between">
              <span>Users</span> 
              <span class="is-felx is-align-items-center">
                <span>{{ usage.users }}/</span>
                <span v-if="subscriptionLimits.users === -1"><icon icon="infinity"/></span>
                <span v-else>{{ subscriptionLimits.users === -1 ? '' : subscriptionLimits.users }}</span>
              </span>
            </div>
          </div>
        </column>
      </columns>
      <columns>
        <column>
          INVOICES
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>Invoice #</th>
                <th>Date</th>
                <th>Status</th>
                <th class="is-narrow">Actions</th>
                <th class="has-text-right">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="invoice in invoices.data" :key="invoice.id">
                <td>
                  <div class="is-flex is-align-items-center">
                    <span class="mr-2">#{{ invoice.number }}</span>
                    <action-button 
                      :working="paying_invoice"
                      class="is-small is-rounded is-danger" 
                      v-if="!invoice.paid_at && !invoice.deleted_at"
                      @click="payInvoice(invoice)">
                      pay now
                    </action-button>
                  </div>
                </td>
                <td>{{ invoice.created_at | asCalendar }}</td>
                <td>
                  <span v-if="!invoice.deleted_at" class="tag"
                    :class="{
                      'is-danger': !invoice.paid_at && !invoice.deleted_at,
                      'is-success': invoice.paid_at && !invoice.deleted_at,
                    }">{{ invoice.paid_at ? 'Paid' : 'Unpaid' }}</span>
                  <span v-if="invoice.deleted_at" class="tag is-danger">Cancelled</span>
                </td>
                <td>
                  <action-button
                    class="is-small is-rounded" @click="downloadInvoice(invoice)">Download</action-button>
                </td>
                <td class="has-text-right">{{ invoice.total | asCurrency(invoice.plan_price.currency) }}</td>
              </tr>
            </tbody>
          </table>

          <pager v-if="invoices.data.length" :pageable="invoices" context="Invoice" jump-controls @nav="goToPage" />

          <no-items-to-display
            :item-count="invoices.data.length"
            heading="There are no invoices available for display."
          />

        </column>
      </columns>
    </column>
    <column>
      <columns>
        <column>
          <card/>
        </column>
      </columns>
      <columns v-if="!account.cancelled_at">
        <column>
          <p>Account Cancellation</p>
          <action-button class="is-rounded is-danger is-small" :working="cancelling" @click="cancelAccount">Cancel Account</action-button>
        </column>
      </columns>
    </column>
  </columns>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import Card from '../billing/partials/Card.vue'
import { get } from '@/api/request'
import download from 'downloadjs'
import { common as backend } from '@/api'
import { billing as billingBackend } from '@/api'

export default {

  components: {
    Card
  },

  data: () => ({
    loading: true,
    cancelling: false,
    paying_invoice: false,
    generatingCheckout: false,
    invoices: {
      data: []
    }
  }),

  created() {
    this.loadInvoices()
  },

  methods: {
    loadInvoices() {
      get('v1' + this.$route.fullPath, ({data}) => {
        this.invoices = data
      }, () => {
        this.loading = false
      })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    async cancelAccount() {
      if (await this.$confirm({
        title: 'Cancel Account',
        message: 'Are you sure you want to cancel your account?'
      })) {
        this.cancelling = true
        this.$store.dispatch('billing/cancelAccount').then(async () => {
          await this.$alert(
            'Cancellation submitted',
            'Your cancellation has been submitted and your data will still be available until the end of the current calendar month.'
          )
          this.cancelling = false
          this.$router.push({
            name: 'dashboard'
          })
        }).catch(() => this.cancelling = false)
      }
    },
    downloadInvoice(invoice) {
      backend.downloadFile(`/v1/invoices/${invoice.id}/download`, ({ data }) => {
        download(data, `inspeckta-invoice-#${invoice.number}.pdf`)
        this.downloading = false
      }, () => {
        this.downloading = false
      })
    },
    payInvoice(invoice) {
      this.paying_invoice = true
      if(!this.account.card || !this.account.card.id) {
        return this.$alert('No Card Available', 'Please add a card before attempting to make payment.')
      }
      billingBackend.payInvoice(invoice.id, ({data}) => {
        console.log(data)
        const index = this.invoices.data.indexOf(invoice)
        this.invoices.data.splice(index, 1, data)
        this.paying_invoice = false
      }, error => {
        this.paying_invoice = false
        console.log(error)
      })
      return invoice
    }
  },

  computed: {
    ...mapGetters('auth', [
      'account'
    ]),
    ...mapGetters('billing', [
      'subscription',
      'subscriptionLimits',
      'usage'
    ])
  },

  watch: {
    '$route': 'loadInvoices'
  }

}
</script>