<template>
<div>
  <columns>
    <column class="is-flex is-align-items-center">
      <h5 class="title is-5">Card</h5>
    </column>
    <column class="has-text-right">
      <action-button
        class="is-rounded is-small is-primary"
        :working="generating_checkout" @click="addCard" v-if="!account.card.id" left-icon="plus">Card</action-button>
      <action-button
        v-if="account.card.id"
        class="is-danger is-small is-rounded"
        @click="deleteCard">
        <icon icon="trash"/>
      </action-button>
    </column>
  </columns>
  <div v-if="account.card.id" class="is-flex is-align-items-center">
    <icon class="mr-3" icon="credit-card" type="far"/> **** **** **** {{ card.last_four }}
  </div>
  <div v-if="account.card.id" class="is-flex is-align-items-center is-justify-content-space-between">
    <span>
      {{ card.card_holder }}
    </span>
    <span>
      {{ card.expiry_month }}/{{ card.expiry_year }}
    </span>
    <span>
      {{ card.brand }}
    </span>
  </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { addCard } from '@/modals'

export default {

  data: () => ({
    generating_checkout: false,
    deleting_card: false
  }),

  methods: {
    async deleteCard() {
      if(await this.$confirm({
        title: 'Delete Card',
        message: 'Are you sure you want to delete this card?',
        confirmClass: 'is-danger'
      })) {
        this.deleting_card = true
        this.$store.dispatch('billing/deleteCard').then(() => {
          this.$store.commit('auth/clearCard')
          this.$toast.success('Card successfully removed.')
          this.deleting_card = false
        }).catch(() => {
          this.deleting_card = false
          this.$toast.error('We were unable to remove your card, please contact support.')
        })
      }
    },
    async addCard() {
      this.generating_checkout = true
      return await this.$store.dispatch('billing/newCardCheckout', {
        return_url: '/verify-card',
      }).then(({data}) => {
        addCard(data)
        this.generating_checkout = false
      }, () => {
        this.generating_checkout = false
      })
    },
  },

  computed: {

    ...mapGetters('auth', [
      'account'
    ]),

    card() {
      return this.account.card
    }
  }

}
</script>